@import "mixins";
@import "variables";

.cap-it-ui-input-filter {
  border-bottom: 1px solid $gray90;
  padding: $quarter-grid-gutter-width;

  .filter-input {
    background-size: $grid-gutter-width $grid-gutter-width;
    font-size: $font-size-base;
  }

  &.base-input-filter-focus {
    @include primary-search-icon();
  }
}

.cap-it-ui-input-filter.disabled {
  @include disabled-input();
}
