@import "~@deere/cap-it-ui.sales-center-branding/styles/variables";
@import "./variables";

$large-max-height: 80px;
$small-max-height: 50px;
$small-max-width: 300 px;

.accordion {
  border-radius: 3px;
}

.accordionBody {
  padding-left: $default-icon-heading-margin + $accordion-padding-left +
    $icon-width;
  padding-right: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
}

.accordionHeader {
  color: $dark-charcoal;
  padding: 0px $grid-gutter-width 2px $accordion-padding-left;
}

.large {
  .accordionHeader {
    font-size: $font-size-h3;
    grid-gap: $large-icon-heading-margin;
    max-height: $large-max-height;
  }

  .accordionBody {
    padding-left: $large-icon-heading-margin + $accordion-padding-left +
      $icon-width;
  }
}

.small {
  .accordionHeader {
    font-size: $font-size-h6;
    grid-gap: $small-icon-heading-margin;
    max-height: $small-max-height;
    max-width: $small-max-width;
  }

  .accordionBody {
    padding-left: $small-icon-heading-margin + $accordion-padding-left +
      $icon-width;
  }
}

.white {
  background-color: $white;
}

.gray {
  background-color: $light-gray-a;

  &:global(.open) {
    background-color: $white;
  }
}
