@import "~@deere/cap-it-ui.sales-center-branding/styles/variables";
@import "./variables";

.accordionHeader {
  align-items: center;
  display: grid;
  cursor: pointer;
  grid-gap: $large-icon-heading-margin;
}

.accordionIcon {
  display: inline-grid;
  grid-area: icon;
}

.left {
  .accordionHeader {
    grid-template-areas: "icon .";
    grid-template-columns: auto 1fr;
  }
}

.right {
  .accordionHeader {
    grid-template-areas: ". icon";
    grid-template-columns: 1fr auto;
  }
}
