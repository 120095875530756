@import "./variables";

/* stylelint-disable */
:export {
  animationDelay: $animationDelay;
}
/* stylelint-enable */

.accordionBody {
  transition: all #{$animationDelay}ms ease-in-out;
}

.enter {
  max-height: 0;
  overflow: hidden;
}

.leave,
.enterActive {
  max-height: 5000px;
  overflow: hidden;
}

.leaveActive {
  max-height: 0;
  overflow: hidden;
}
