@import "variables";
@import "~@deere/cap-it-ui.sales-center-branding/styles/mixins";
@import "~@deere/cap-it-ui.sales-center-branding/styles/themes";

@mixin reset-button {
  align-items: center;
  background: none;
  border: none;
  display: inline-flex;
  justify-content: center;
  margin-left: $half-grid-gutter-width;
  outline: none;
  padding: 0;
}

@mixin clear-button {
  .clear-button {
    @include reset-button();
  }
}

@mixin search-button {
  .search-button {
    @include reset-button();
  }
}

@mixin filter-input {
  .filter-input {
    border: 0;
    flex-grow: 1;
    font-family: $body-font;
    height: 100%;
    outline: none;

    &::-webkit-search-cancel-button {
      appearance: none;
    }

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }

    &::placeholder {
      color: $medium-gray-a;
    }
  }
}

@mixin disabled-input {
  background: $medium-gray-b;
  border: none;

  .filter-input {
    background: $medium-gray-b;
  }

  .search-button {
    @include disabled-search-icon();
  }
}

/* stylelint-disable declaration-no-important */

@mixin primary-search-icon {
  .search path {
    @include from-theme() {
      fill: use(primary-color) !important;
    }
  }
}

@mixin inverted-search-icon {
  .search path {
    fill: $white !important;
  }
}

@mixin disabled-search-icon {
  .search path {
    fill: $medium-gray-a !important;
  }
}

/* stylelint-enable declaration-no-important */
