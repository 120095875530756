$border-color: #ddd;
$input-background: #fff;
$input-font-color: #5e5e5e;
$filter-input-height: 20px;

.filter {
  dl {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0;
  }

  dt,
  dd {
    margin: 0;
  }

  dt {
    width: 75px;
  }

  dd {
    width: 125px;
  }

  dt::after {
    content: ": ";
  }

  ul {
    list-style: none;
    margin: 10px 0 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
  }
}

.container {
  overflow-y: auto;
  padding-left: 25px;
}

.containerViewOnly {
  overflow-y: none;
  padding-left: 50px;
  font-size: 14px;
}

.fontStyle {
  font-size: 14px;
  padding-left: 12px;
}

.fontSize {
  font-size: 16px;
}

.gray {
  color: #808080;
}

.checkMark {
  li {
    list-style: none;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23367c2b' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
      no-repeat center left 2px;
    padding-left: 30px;
  }
}

.actionsButton:after,
.actionsButton:hover:after {
  background-image: none;
  height: 0;
  width: 0;
}

.tickMark {
  list-style: none;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23367c2b' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
    no-repeat center left 2px;
  padding-left: 25px;
  font-size: 14px;
  width: max-content !important;
}

.styleDeter {
  padding-right: 3px;
  padding-top: 3px;
  display: block;
}
.icon-search {
  height: $filter-input-height;
  margin: 0 5px;
  width: $filter-input-height;
}

.filter-input {
  border: 0;
  box-sizing: border-box;
  color: $input-font-color;
  display: block;
  height: $filter-input-height;
  margin: 0;
  padding: 0;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.filter-wrapper {
  background: none repeat scroll 0 0 $input-background;
  border: 1px solid $border-color;
  border-radius: 25px;
  display: flex;
  overflow: hidden;
  padding: 3px;
}
