.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toast.bg-uxf-charcoal .toast-header button.close span {
  color: #ffffff;
}

​​ .list-auto-scroll {
  overflow-y: auto;
  max-height: 150px;
}

.view-result {
  z-index: 0;
}

.modalScroll {
  overflow-y: auto !important;
}

.modal-body-scroll {
  overflow-y: auto;
  max-height: 333px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sdf-accordion .uxf-accordion-item button {
  background-color: #fff !important;
  padding: 0px;
}

.sdf-accordion .card-body {
  overflow-y: auto;
  max-height: 150px;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:before {
  background-color: #ffffff;
}

.popover-underline {
  border-bottom: 1px dashed;
}
.search-input {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23aaa' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
    no-repeat top 7px right 10px #ffffff;
  height: 40px;
}
.search-input:focus {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23367c2b' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
    no-repeat top 7px right 10px #ffffff;
}

.modal-dialog .alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
}
.modal-dialog .alert.alert-success {
  background-color: #d6e5d3;
}
.modal-dialog .alert.alert-warning {
  background-color: #fef0d9;
}
.modal-dialog .alert.alert-danger {
  background-color: #f6dbde;
}
.modal-dialog .alert.alert-info {
  background-color: #d9e7f4;
}

.unstyledCheckbox {
  height: 20px;
  width: 20px;
}

body {
  max-width: 1440px;
}
