$uxf-box-shadow-color: rgba(0, 0, 0, 0.175) !default;
$uxf-box-shadow: 0 1px 8px $uxf-box-shadow-color !default;
.jdsn-facet-container {
  box-shadow: $uxf-box-shadow;
  .jdsn-facet {
    margin-top: 0;
    border-bottom: 1px solid #cccccc;
    &:last-of-type {
      border-bottom: 0;
    }
    .btn + .btn {
      margin-left: 0;
    }
    .card + .card {
      margin-top: 0;
      border-top: 1px solid #cccccc;
    }
    .overflow-scroll {
      overflow-y: auto;
      max-height: 100px;
      &-sm {
        max-height: 50px;
      }
      &-lg {
        max-height: 300px;
      }
      /* Start Scrollbars, not supported in Firefox or IE/Edge */
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #eeeeee;
        border: 1px solid #dddddd;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: #999999;
        border-radius: 2px;
        &:hover {
          background: #666666;
        }
      }
      /* End Scrollbars */
    }
    .card-header {
      h5 button {
        &.btn-link:focus {
          box-shadow: none;
        }
        &[aria-expanded="true"]:focus .uxf-accordion-title {
          text-decoration: none;
        }
      }
      ul {
        list-style: none;
        li {
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23367c2b' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
            no-repeat center left 12px;
          padding-left: 42px;
        }
      }
      .view-more-less {
        cursor: pointer;
        &:after {
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23367c2b' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E")
            no-repeat;
          content: " ";
          background-size: contain;
          //fill: $uxf-green;
          width: 1.8rem;
          height: 1.6rem;
          position: absolute;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        &.collapsed:after {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
          margin-left: 0;
        }
      }
    }
    .card-body {
      .filter-search-within {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23aaa' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
          no-repeat center right 5px;
        border-width: 0 0 1px 0;
        border-color: #cccccc;
        padding: 0 1.75rem 0 0.5rem;
        &:focus {
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23367c2b' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
            no-repeat center right 5px;
          border-color: #367c2b;
          box-shadow: none;
        }
      }
      .custom-control {
        margin: 0.25rem 0.25rem 0 0.25rem;
        label {
          width: 100%;
          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
      ul {
        list-style: none;
        li {
          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
}
