main .btn + .btn {
  margin-left: 0.5rem;
}
main .display-5 {
  font-size: 3rem;
}
main .display-6 {
  font-size: 2.5rem;
}
main .display-7 {
  font-size: 2rem;
}
.modal-open {
  padding-right: 0px !important;
}
