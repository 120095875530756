@import "~@deere/cap-it-ui.sales-center-branding/styles/themes";
@import "mixins";
@import "variables";
@import "input-filter";
@import "search-input-filter";

.cap-it-ui-search-input-filter,
.cap-it-ui-input-filter {
  @include clear-button();
  @include search-button();
  @include filter-input();
  align-items: center;
  background-color: $white;
  display: flex;

  .filter-label {
    display: none;
  }

  &.base-input-filter-focus {
    @include from-theme() {
      border-color: use(primary-color);
    }

    .filter-input::placeholder {
      color: $medium-gray-b;
    }
  }

  ul {
    list-style: none; /* Remove list bullets */
    padding: 0;
    margin: 0;
  }

  li {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23367c2b' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
      no-repeat center left 12px;
    padding-left: 42px;
  }
}
