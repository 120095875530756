@import "mixins";
@import "variables";
@import "~@deere/cap-it-ui.sales-center-branding/styles/themes";

.cap-it-ui-search-input-filter {
  border: 10px solid $white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  height: $search-input-height;
  padding-left: $half-grid-gutter-width;

  .filter-input {
    font-size: $font-size-h6;
  }

  .disabled {
    color: $medium-gray-a;
  }

  .search-button {
    @include primary-search-icon();
    height: $search-input-height;
    position: relative;
    right: -1px;
    width: $search-input-height;

    &.inverted {
      @include from-theme() {
        background-color: use(primary-color);
      }

      @include inverted-search-icon();
    }
  }

  .constraint-button {
    padding-right: 0;
  }

  .cap-it-ui-search-input-filter-popover {
    margin-top: $search-input-height / 3;
  }
}

.cap-it-ui-search-input-filter.disabled {
  @include disabled-input();
}
